import React from "react";
import MainImage from "./MainImage";
import ReactPlayer from "react-player";
import InstagramEmbed from "react-instagram-embed";
import LatexRenderer from "./Latex";

import styles from './serializers.module.css'

const AuthorReference = ({ node }) => {
  if (node && node.author && node.author.name) {
    return <span>{node.author.name}</span>;
  }
  return <></>;
};

const serializers = {
  types: {
    authorReference: AuthorReference,
    mainImage: ({ node }) => <MainImage mainImage={node} />,
    videoEmbed: ({ node }) => <ReactPlayer className="mt-6 mb-6" url={node.url} controls />,
    instagram: ({ node }) => {
      if (!node.url) return null;
      return <InstagramEmbed url={node.url} className="container mx-auto mt-6 mb-6" />;
    },
    math: ({ node, isInline = false }) => <LatexRenderer isInline={isInline} latex={node.latex} />,
    textFile: ({ node = {} }) => {
      const {
        name,
        file: {
          asset: {
            url,
          } = {},
        } = {},
      } = node
      return <a className={styles.link} target="_blank" href={url}>{name}</a>
    },
  },
  marks: {
    link: ({mark, children}) => {

      const { blank, href } = mark
      return blank
        ? <a href={href}>{children}</a>
        : <a href={href} target="_blank" rel="noopener">{children}</a>
    }
  }
};

export default serializers;
